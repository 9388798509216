import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ChangePassword from "./components/ChangePassword";
import Login from "./components/Login";
import Admin from "./components/Admin";
import ReasonUpdate from "./components/manage/ReasonUpdate";
import IntroductionUpdate from "./components/manage/IntroductionUpdate";
import UserManage from "./components/manage/user/UserManage";
import UserDetail from "./components/manage/user/UserDetail";
import SubscriptionManage from "./components/manage/subscription/SubscriptionManage";
import VideoManage from "./components/manage/video/VideoManage";
import AddVideo from "./components/manage/video/AddVideo";
import SoundManage from "./components/manage/sound/SoundManage";
import AddSound from "./components/manage/sound/AddSound";
import CoinManage from "./components/manage/coin/CoinManage";
import TagManage from "./components/manage/tag/TagManage";
import AddTag from "./components/manage/tag/AddTag";
import NotificationManage from "./components/manage/notification/NotificationManage";
import AddNotification from "./components/manage/notification/AddNotification";
import UpdateVideo from "./components/manage/video/UpdateVideo";
import UpdateSound from "./components/manage/sound/UpdateSound";
import UpdateTag from "./components/manage/tag/UpdateTag";
import UpdateNotification from "./components/manage/notification/UpdateNotification";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/verify/:id/:token" element={<ChangePassword />} />
          <Route path="/" element={<Login />} />
          <Route path="/reason_update" element={<ReasonUpdate />} />
          <Route path="/introduction_update" element={<IntroductionUpdate />} />
          <Route path="/manage" element={<Admin />}>
            <Route index element={<UserManage />} />
            <Route path="user_detail/:id" element={<UserDetail />} />
            <Route path="subscription" element={<SubscriptionManage />} />
            <Route path="video" element={<VideoManage />} />
            <Route path="add_video" element={<AddVideo />} />
            <Route path="update_video/:id" element={<UpdateVideo />} />
            <Route path="sound" element={<SoundManage />} />
            <Route path="add_sound" element={<AddSound />} />
            <Route path="update_sound/:id" element={<UpdateSound />} />
            <Route path="coin" element={<CoinManage />} />
            <Route path="tag" element={<TagManage />} />
            <Route path="add_tag" element={<AddTag />} />
            <Route path="update_tag/:id" element={<UpdateTag />} />
            <Route path="notification" element={<NotificationManage />} />
            <Route path="add_notification" element={<AddNotification />} />
            <Route
              path="update_notification/:id"
              element={<UpdateNotification />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
